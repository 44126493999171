/* Adjust any specific Bootstrap overrides or additional custom styling */
.card-header {
    background-color: #270b79 !important; /* Header background color */
  }
  
  .nav-tabs .nav-link.active {
    background-color: #fff !important;
    border-color: #fff !important;
    color: #823455 !important;
  }

  .nav-item a{
    background-color: #eff4f9;
    color: #656565 !important;
    border-radius: 0px;
    font-size: 13px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  .nav-tabs .nav-link:hover{
    border-color:#eff4f9 !important;
  }
  
  .btn-success {
    background-color: #00A192 !important; /* Green buttons */
  }
  
  .table thead {
    background-color: #270b79;
    color: white;
  }
  

  .w-40{
    width: 40% !important;
  }

  .w-55{
    width: 55% !important;
  }
  

  .labelinpruchaseorder{
    font-size: 12px !important;
    /* color: #270b79 !important; */
  }

  /* .inputdesign{
    border: none !important;
    border-bottom: 1.5px solid #757575 !important;
    border-radius: 0px !important;
  } */

  .form-control:focus {
    color: var(--bs-body-color);
    background-color: #fff !important;
    /* border-color: #fff !important; */
    outline: 0;
    box-shadow: none !important;
}





  
  /* Webkit Browsers (Chrome, Safari) */
  /* Scrollbar overall */
  .sidebarbox::-webkit-scrollbar {
    width: 6px; /* Width of the scrollbar */
  }
  
  /* Track (background of scrollbar) */
  .sidebarbox::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the track (scroll background) */
    border-radius: 10px;
  }
  
  /* Handle (scrollbar itself) */
  .sidebarbox::-webkit-scrollbar-thumb {
    background: #757575; /* Scrollbar color */
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .sidebarbox::-webkit-scrollbar-thumb:hover {
    background: #00A192; /* Hover color for the scrollbar */
  }
  
  /* Firefox */
  .sidebarbox {
    scrollbar-width: thin;
    scrollbar-color: #757575 #f1f1f1; /* Scrollbar color, Track color */
  }
  .newscroller::-webkit-scrollbar {
    width: 6px; /* Width of the scrollbar */
  }
  
  /* Track (background of scrollbar) */
  .newscroller::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the track (scroll background) */
    border-radius: 10px;
  }
  
  /* Handle (scrollbar itself) */
  .newscroller::-webkit-scrollbar-thumb {
    background: #757575; /* Scrollbar color */
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .newscroller::-webkit-scrollbar-thumb:hover {
    background: #00A192; /* Hover color for the scrollbar */
  }
  
  /* Firefox */
  .newscroller {
    scrollbar-width: thin;
    scrollbar-color: #757575 #f1f1f1; /* Scrollbar color, Track color */
  }
  
  .tableinput{
   background-color: #f8f9fa; 
   border: 1px solid #f8f9fa;
    border-radius: 2px;
    font-size: 13px;
  }

  .tableinput:focus{
    background-color: #f8f9fa !important;
    border-color: #f8f9fa !important;
  }
