body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f1f1f1;
}

.chrome-tabs-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 2.6px solid #fd8c5c;
  padding: 10px 0 0 0;
  background-color: #ddd;
}

.tabs {
       display: flex  ;
      align-items: center;
      /* height: 32px; */
      position: relative;
      /* border-bottom: 2px solid #bbb; */
      overflow: hidden;
}

.tab {
  display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 150px;
    height: 100%;
    /* background-color: #e6e6e6; */
    /* border-top-left-radius: 8px; */
    /* border-top-right-radius: 8px; */
    border-right: 2px solid #ccc;
    padding: 0 15px;
    cursor: pointer;
    position: relative;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
    transition: background-color 0.3s ease;
}

.tab:first-child {
  margin-left: 0;
}

.tab.active {
  background-color: #fc8b5c !important;
  border-bottom: 2px solid #fc8b5c;
  z-index: 1;
    border-top-left-radius: 11px; 
    border-top-right-radius: 11px; 
}

.tab.active > a{
  color: white !important;
}
.tab.active > button{
  color: white !important;
}

.tab:hover {
  background-color: #f5f5f5;
}

.tab-title {
  flex: 1;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.close-btn {
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: #666;
}

.close-btn:hover {
  color: red;
}

.add-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 100%;
  background-color: #e6e6e6;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 20px;
  cursor: pointer;
  border-left: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.tab::before {
  content: '';
  position: absolute;
  right: -2px;
  top: 0;
  width: 2px;
  height: 20%;
  background-color: #dddddd; /* Matches the background of the parent */
  z-index: 1; /* Ensures it overlays the border */
}

.tab::after {
  content: '';
  position: absolute;
  right: -2px;
  bottom: 0;
  width: 2px;
  height: 20%;
  background-color: #dddddd; /* Matches the background of the parent */
  z-index: 1;
}

.add-tab:hover {
  /* background-color: #f5f5f5; */
  background-color: #f4eded;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}





/* Container styling */
.login-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;     /* Center vertically */
  height: 100vh;           /* Full height of the viewport */
  position: relative;
}


#root > div > header > div.d-flex.align-items-center.mx-1 > div > div > button:hover{
  border-color: white !important;
}

/* Upper section with #270b79 background */
.upper-section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 43vh;
  /* background-color: #270b79;  */
  background: linear-gradient(90deg, #391F84 0%, #FF8D5C 100%);
  display: flex;
  justify-content: center;
  align-items: end;
}

/* Lower section with #eff4f9 background */
.lower-section {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 57vh; /* 60% of the screen height */
  background-color: #eff4f9; /* Light gray color */
  display: flex;
  /* justify-content: center; */
align-items: center;
flex-direction: column;
}

/* Login box styling */
.login-box {
  background-color: white;
  padding: 16px;
  width: 300px;
  min-width: 25%;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  border: 1px solid #eff4f9;
  z-index: 1; /* Ensure it stays above the section backgrounds */
  text-align: center;
  /* margin-top: 20px; Add margin to avoid overlap */
}

select{
  background-color: #eff4f9;
  outline: none;
  padding: 8px 0px;
  font-size: 13px;
  padding-left:5px !important;
}
input{
  background-color: #eff4f9;
  outline: none;
  font-size: 13px;
  padding-left:5px !important;
}

.controlinput{
  width: 100%;
  overflow: hidden;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-bottom: 15px;
}

/* Website logo styling */
.logo {
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 10px;
  position: relative; /* To position the border */
  margin-bottom: 0px; /* Space between logo and border */
  color: #270b79;
}


/* Login form input styling */
.login-form input {
  display: block;
  width: 100%;
  padding: 8px 0px;
  border: 1px solid #eff4f9; /* Use valid color */
  border-radius: 4px;
}


.login-form select {
  display: block;
  width: 100%;
  margin-bottom: 15px;
  padding: 8px;
  border: 1px solid #eff4f9; /* Use valid color */
  border-radius: 4px;
}

/* Login button styling */
.login-form button {
  width: 100%;
  padding: 8px;
  background-color: #270b79; /* Dark blue color */
  color: white;
  border: none;
  font-size: 12px;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form button:hover {
  background-color: #1e0a5d; /* Darker shade of blue */
}


.header{
  /* background-color: #270b79; */
  background: linear-gradient(90deg, #391F84 0%, #FF8D5C 100%);
  height: 56px;
}

.hamburggericon{
  text-decoration: none;
  width: 36px;
  color: #fff;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgb(0 0 0 / 25%);
  margin: 0px 8px;
}


.custom-design-btn{
  background: #ec642a;
    padding: 2px 14px;
    border-radius: 2px;
}



.dashboard-container {
  padding: 20px;
}

.dashboard-header {
  margin-bottom: 20px;
}

.dashboard-header h2 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.dashboard-card {
  background-color: #fff;
  /* border-radius: 10px; */
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.dashboard-card h5 {
  font-size: 18px;
  font-weight: 600;
  color: #461A57;
  border-bottom: 2px solid #823455;
  padding-bottom: 10px;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.btn:hover {
  color: none !important;
  background-color:none !important;
  border-color: none !important;
}

.table th, .table td {
  text-align: left;
  padding: 10px;
}
.table td{
  background-color: white !important;
  font-size: 13px !important;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.createbtn:hover {
  background-color: #FF8D5C !important;
  color: white;
  border: 1px solid #FF8D5C;
}


.createbtn:hover {
  background-color: #FF8D5C !important;
  color: white;
  border: 1px solid #FF8D5C !important;
}



/* page title design here */

.pagetitle{
  color: #270b79;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  padding: 0.7rem 0px;
  display: block;
  /* border-bottom: 1px solid #e2e5ea; */
  background-color: #fff9;
}


th{
  /* background-color:#823455!important; */
  background-color:#f5f5f5!important;
  /* color: #262626 !important; */
  color: #6c757d !important;
  font-size: 0.9rem !important;
  font-weight: 500 !important;
  padding: 0.35rem !important;
}

td{
  padding: 0.35rem !important;
}

.footer{
font-weight: 600;
font-size: .8rem;
color: #823455;
text-align: center;
padding-top: 1rem;
padding-bottom: 1rem;
background-color: #ffffff99;
border-top: 1px solid #e2e5ea;
margin-top: auto;
}

header > div:nth-child(1) > p > div > div > button:nth-child(2) > div > div:nth-child(2) > div{
  top: 32px !important;
}
header > div:nth-child(1) > p > div > div > button:nth-child(2) > div > div:nth-child(4) > div{
  top: 98px !important;
}
header > div:nth-child(1) > p > div > div > button:nth-child(2) > div > div:nth-child(5) > div{
  top: 133px !important;
}
header > div:nth-child(1) > p > div > div > button:nth-child(2) > div > div:nth-child(6) > div{
  top: 167px !important;
}
header > div:nth-child(1) > p > div > div > button:nth-child(3) > div > div:nth-child(2) > div{
  top: 33px !important;
}
header > div:nth-child(1) > p > div > div > button:nth-child(3) > div > div:nth-child(3) > div{
  top: 66px !important;
}

header > div:nth-child(1) > p > div > div > button:nth-child(3) > div > div:nth-child(5) > div{
  top: 0px !important;
}

header > div:nth-child(1) > p > div > div > button:nth-child(3) > div > div:nth-child(6) > div{
  top: 0px !important;
}

header > div:nth-child(1) > p > div > div > button:nth-child(3) > div > div:nth-child(7) > div{
  top: 101px !important;
}

header > div:nth-child(1) > p > div > div > button:nth-child(3) > div > div:nth-child(8) > div{
  top: 201px !important;
}

/* submenu  */
header > div:nth-child(1) > p > div > div > button:nth-child(3) > div{
  top: 0px !important;
}

header > div:nth-child(1) > p > div > div > button:nth-child(4) > div{
  /* top: 114px !important; */
  top: 0px !important;
}

header > div:nth-child(1) > p > div > div > button:nth-child(4) > div > div:nth-child(2) > div{
  top: 32px !important;
}
header > div:nth-child(1) > p > div > div > button:nth-child(4) > div > div:nth-child(3) > div{
  top: 66px !important;
}
header > div:nth-child(1) > p > div > div > button:nth-child(4) > div > div:nth-child(5) > div{
  top:135px !important;
}
header > div:nth-child(1) > p > div > div > button:nth-child(4) > div > div:nth-child(6) > div{
  top:168px !important;
}
header > div:nth-child(1) > p > div > div > button:nth-child(4) > div > div:nth-child(6) > div{
  top:168px !important;
}
header > div:nth-child(1) > p > div > div > button:nth-child(4) > div > div:nth-child(4) > div{
  top:99px !important;
}

header > div:nth-child(1) > p > div > div > button:nth-child(4) > div > div:nth-child(7) > div{
  top: 202px !important;
}

header > div:nth-child(1) > p > div > div > button:nth-child(1) > div > div:nth-child(2) > div{
  top: 33px !important;
}
/* header > div:nth-child(1) > p > div > div > button:nth-child(2) > div{
  top:37px !important;
} */
header > div:nth-child(1) > p > div > div > button:nth-child(1) > div > div:nth-child(4) > div{
  top: 99px !important;
}

header > div:nth-child(1) > p > div > div > button:nth-child(1) > div > div:nth-child(5) > div{
  top: 133px !important;
}
header > div:nth-child(1) > p > div > div > button:nth-child(1) > div > div:nth-child(6) > div{
  top: 167px !important;
}

header > div:nth-child(1) > p > div > div > button:nth-child(2) > div > div:nth-child(3) > div{
  top: 66px !important;
}

header > div:nth-child(1) > p > div > div > button:nth-child(2) > div > div:nth-child(7) > div{
  top: 200px !important;
}

header > div:nth-child(1) > p > div > div > button:nth-child(2) > div > div:nth-child(8) > div{
top: 235px !important;
}
header > div:nth-child(1) > p > div > div > button:nth-child(4) > div > div:nth-child(8) > div{
top: 235px !important;
}
header > div:nth-child(1) > p > div > div > button:nth-child(5) > div{
  top: 154px !important;
}
.showfilter{
height: 100%;
display: flex;
transition: all 2s ease;
}

.hidefilter{
height: 0px;
display: none;
}



/* 404 page code  */


body{
  background-color: #eff4f9 !important;
}


#notfound {
  position: relative;
  height: 82vh;
}



#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound .notfound-404 {
  position: relative;
  height: 240px;
}

.notfound .notfound-404 h3 {
  font-family: 'Cabin', sans-serif;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #262626;
  margin: 0px;
  letter-spacing: 3px;
  padding-left: 6px;
}

.notfound .notfound-404 h1 {
  font-family: 'Montserrat', sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 252px;
  font-weight: 900;
  margin: 0px;
  color: #262626;
  text-transform: uppercase;
  letter-spacing: -40px;
  margin-left: -20px;
}

.notfound .notfound-404 h1>span {
  text-shadow: -8px 0px 0px #fff;
}

.notfound h2 {
  font-family: 'Cabin', sans-serif;
  font-size: 20px;
  font-weight: 400;
  /* text-transform: uppercase; */
  color: #000;
  margin-top: 0px;
  margin-bottom: 25px;
}


/* 404 page end here  */



.btn:hover {
  /* color: var(--bs-btn-hover-color); */
  background-color: transparent !important;
  /* border-color: var(--bs-btn-hover-border-color); */
}


.actionbtn{
  padding: 3px 5px;
    display: block;
    height: 22px;
    font-size: 25px;
    border-radius: 3px;
    background-color: #fff !important;
    color: #412889 !important;
}




/* purchaseorder css file  */

/* PurchaseOrder.css */

.table-header {
  font-size: 0.8rem; /* Reduces font size slightly */
  white-space: nowrap; /* Prevents line wrapping */
}

.table {
  font-size: 0.8rem; /* Optional: Reduce overall table font size */
}

.table th, .table td {
  text-align: center; /* Center align text for better presentation */
}

.table th {
  padding: 0.75rem; /* Adjust padding if necessary */
}

.maindiv{
  background-color: #eff4f9;
}

.createbtn{
text-decoration: none;
  background: #FF8D5C;
   color: #fff;
  display: flex;
  align-items: center;
  border: 1px solid #FF8D5C;
  height: 32px;
  padding-left: 11px;
  transition: all 0.3s ease;
  padding-right: 11px;
  border-radius: 2px;
  cursor: pointer;
  font-size: 0.8rem !important;
}
.createbtn:hover{
  background-color: #FF8D5C !important;
  color: white !important;
  border: 1px solid #FF8D5C !important;
}
.createbtn:hover > span{
  /* background-color: #fff; */
  /* color: #FF8D5C; */
  /* border: 1px solid #270b79; */
}















.createbtn2{
/* background-color: #270b79; */
background: #823455;
 color: #fff;
display: flex;
align-items: center;
border: 1px solid #823455;
height: 32px;
padding-left: 11px;
transition: all 0.3s ease;
padding-right: 11px;
border-radius: 2px;
cursor: pointer;
font-size: 0.8rem !important;
}
.createbtn2:focus{
background-color: #823455;
color: white;
border: 1px solid #823455;
}
.createbtn2:hover{
background-color: #823455;
color: white;
border: 1px solid #823455;
}
.createbtn2:hover > span{
/* background-color: #fff; */
/* color: #FF8D5C; */
/* border: 1px solid #270b79; */
}














.createbtn-orange{
background-color: #F1F1F1;
 color: #4B5563;
display: flex;
align-items: center;
border: 1px solid #F1F1F1;
height: 32px;
padding-left: 11px;
transition: all 0.3s ease;
padding-right: 11px;
border-radius: 2px;
cursor: pointer;
font-size: 0.8rem !important;
}
.createbtn-orange:hover{
background-color: #F1F1F1;
color: #4B5563;
border: 1px solid #F1F1F1;
}
.createbtn-orange:hover > span{
/* background-color: #fff; */
color: #4B5563 !important;
/* border: 1px solid #270b79; */
}



.importbtn{
  background-color: #F1F1F1 !important;

  color: #4B5563 !important;
  display: flex;
  align-items: center;
  border: 1px solid #F1F1F1;
  height: 32px;
  padding-left: 5px;
  transition: all 0.3s ease;
  padding-right: 7px;
  border-radius: 2px;
  cursor: pointer;
  font-size: 0.8rem !important;
}
.importbtn:hover{
  /* color: #fff !important; */
  background-color: #F1F1F1 !important;
  border: 1px solid #F1F1F1;
}

.iconbox{
  height: 100%;
  display: flex;
  align-items: center;
  /* border-right: 0.2px solid rgb(224 224 224 / 38%); */
  /* margin-right: 4px; */
}

.iconbox-left{
  height: 100%;
  display: flex;
  align-items: center;
  /* border-left: 0.2px solid rgb(224 224 224 / 38%); */
  /* margin-left: 4px; */
}


.filterbtn{
  background-color: #fff;
  color: #270b79;
  display: flex;
  align-items: center;
  border: 1px solid #e2e5ea;
  height: 32px;
  padding-left: 5px;
  transition: all 0.3s ease;
  padding-right: 7px;
  border-radius: 2px;
  cursor: pointer;
  font-size: 0.8rem !important;
}

.optionbtn{
  background-color: #823455 !important;
  padding: 1px 12px;
  border: 0px;
  font-size: 14px;
  color: white;
}



.table-responsive > table > tbody > tr > td:nth-child(1) > div > button{
padding: 0px !important;
border: 0px solid white !important;
}




td{
  background-color:#f8f9fa !important;
}

.optionbtn:hover{
background-color: #823455 !important;
color: #fff !important;
}
.pagination .page-link {
  font-size: 0.875rem; /* Adjust font size as needed */
}

.pagination .page-item.active .page-link {
  background-color: #412889; /* Your active color */
  border-color: #412889; /* Your active border color */
}

.pagination .page-item.disabled .page-link {
  color: #6c757d; /* Change color for disabled state */
}


.page-item{
  color: #412889;
  padding: 3px 5px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  font-size: 0.8rem;
  font-weight: 500;
  background-color: #ffffff99;
  margin-right: 2px;
  border: 1px solid #e2e5ea !important;
}

.active{
  background-color: #412889 !important;
  color: white !important;
}
th{
  text-wrap: nowrap !important;
}