.user-dropdown .btn {
    /* background-color: #270b79; */
    background-color: transparent;
    border: 0.1px solid #fff;
    border-radius: 0px;
    font-size: 0.8rem;
    font-weight: 500;

  }
  
  .user-icon {
    margin-right: 5px;
  }
  
  .dropdown-menu {
    min-width: 200px;
  }
  
  .dropdown-item {
    display: flex;
    align-items: center;
  }
  
  .dropdown-item svg {
    margin-right: 10px;
  }
  
  .dropdown-item.text-danger {
    color: #FF3B3F !important;
  }
  
  .dropdown-item:hover {
    background-color:rgb(0 0 0 / 25%);
  }

  
  .dropdown-toggle::after {
    margin-left: 10px;
  }


  .btn:first-child:active, :not(.btn-check)+.btn:active {
    color: var(--bs-btn-active-color);
    background-color: transparent;
    border-color: #fff ;
}
 
.no-active{
  color: var(--bs-btn-active-color);
    /* background-color: #270b79 !important;
    border-color: #270b79 !important; */
}

.dropdown-menu {
    min-width: 235px;
    position: absolute;
    top: 11px !important;
    border-radius: 0px;
    inset: 0px 0px auto auto;
    transform: translate3d(-0.8px, 32.8px, 0px);
}



/* Main Meun style here  */

div:nth-child(1) > p > div > button::after{
  display: none;
}

div:nth-child(1) > p > div > div{
  top: 4px !important;
}

div:nth-child(1) > p > div > div button{
  background-color: #fbfcfd;
  padding: 0.6rem;
  border-bottom: 1px solid #f0f0f0;
}

div:nth-child(1) > p > div > div{
  padding: 0px;
}

.submenu {
  /* Add transition for smoother appearance */
  transition: opacity 0.3s ease;
  opacity: 1; /* Keep it visible */
}

.user-dropdown:hover .submenu {
  display: block; /* Ensure submenu shows on parent hover */
}

.submenu button{
  font-size: 13px !important;
  background-color: white !important;
  padding: 0.42rem !important;
  color: #270b79 !important;
}
.desginicon{
  color: #270b79;
  font-size: 18px;
}

div:nth-child(1) > p > div > div > button:hover{
  background-color: #FF8D5C !important;
  color: white !important;
  }
  div:nth-child(1) > p > div > div > button:hover > span{
    background-color: #FF8D5C !important;
    color: white !important;
    }

    div:nth-child(1) > p > div > div > button:hover > .desginicon{
      background-color: #FF8D5C !important;
      color: white !important;
      }
/* Main Meun style here  */
